/*
  Automatically instantiates modules based on data-attributes
  specifying module file-names.
*/
const moduleElements = document.querySelectorAll("[data-modules]");
for (let i = 0; i < moduleElements.length; i++) {
  const el = moduleElements[i];
  const modules = el.getAttribute("data-modules").split(" ");
  modules.forEach(e => {
    let m;
    m = require(`./${e}`);
    const Module = m.default;
    new Module(el);
  });
}

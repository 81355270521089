export default function() {
  var flashs = $('#flash').html();
  if (flashs) {
    jQuery.parseJSON(flashs).forEach(function (flash) {
      Object.keys(flash).forEach(function (type) {
        if ( type != 'timedout' ) {
          let msg = flash[type]
          $.growl(msg, type)
        }
      })
    })
  }
}

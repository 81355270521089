$ ->
  $('.stage-select').change ->
    companyId = $(@).attr('metric_company_id')

    $.ajax
      url: '/update_stage/'+ companyId
      method: 'POST'
      data:{ 'stage': @value }

      success: ->
        location.reload()

      error: ( error ) ->
        console.log error.statusText

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import moment from 'moment';
import 'bootstrap';

import Chartkick from "chartkick";
import Chart from "chart.js";


import './../javascripts/common/javascript/google_analytics';
import './../javascripts/common/javascript/bootstrap-growl.js';
import './../javascripts/common/javascript/main.js.coffee';
import './../javascripts/common/javascript/superadmin.js.coffee';

import './../javascripts/modules'

import { I18n } from './../javascripts/modules/i18n';
import flash from './../javascripts/modules/flash';
flash()

require("@rails/ujs").start();

moment.locale( I18n.locale );

Chartkick.use( Chart );
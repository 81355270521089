const images = require.context('./../../images', true)
const imagePath = (name) => images(name, true)

export default function () {
  var imageEl = document.getElementById('image');

  var i = 0;

  function slide() {
    var arr = [
      "bg-01.jpg",
      "bg-02.jpg",
      "bg-04.jpg",
      "bg-05.jpg",
      "bg-06.jpg",
      "bg-07.jpg",
      "bg-08.jpg",
      "bg-09.jpeg",
    ];
    imageEl.src = imagePath(`./login-backgrounds/${arr[i]}`).default;
    i++;
    if (i === arr.length) {
      return i = 0;
    }
  };

  slide();

  setInterval(slide, 10000);
}
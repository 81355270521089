import 'selectize';

export default function() {
  var adminSelect = $("#admin_id").selectize({ 
    maxItems: null,
    onChange: function() {
      $('#admins_ids').val( adminSelect.val() ) 
    }
  });
  var companySelect = $('#company_id').selectize({
    maxItems: null,
    onChange: function() {
      $('#companies_ids').val( companySelect.val() )
      $.ajax({
        type: "GET",
        url: "/notifications/new?companies_ids=" + companySelect.val(),
        success: function (data) {
          adminSelect[0].selectize.destroy()
          $("#admin_id").html( $(data).find("#admin_id").html() )
          adminSelect = $("#admin_id").selectize({ 
            maxItems: null,
            onChange: function() {
              $('#admins_ids').val( adminSelect.val() ) 
            }
          });
        }
      });
    }
  })
}

export default function () {
  $(".state-search").hide()
  $("#tabcontent-map").hide();
  $("#companies-btn").addClass("active-tab");

  $("#companies-btn").click(function () {
    $("#tabcontent-map").hide("slow");
    $("#tabcontent-companies").show("slow");
    $(".state-search").hide()
    $("#companies-btn").addClass("active-tab");
    $("#maps").removeClass("active-tab");
  });

  $("#maps").click(function () {
    $("#tabcontent-companies").hide("slow");
    $("#tabcontent-map").show("slow");
    $("#maps").addClass("active-tab");
    $("#companies-btn").removeClass("active-tab");
    $(".state-search").show()
  });
  var search = $("#search").val();
  var stage_search = $("#stage_search option:selected").val();
  var all_companies
  $.ajax({
    url: `/?format=json&search=${search}&stage_search=${stage_search}`,
    type: "get",
    dataType: "json",
    success: function (data) {
      all_companies = data
      google.maps.event.addDomListener(window, "load", initialize_map(all_companies));
    },
  });

  $("#state_search").change(function () {
    var filtred_companies
    const value = $(this).val()
    filtred_companies = all_companies.filter(function (company) {
      if (value == "active") {
        return !company.table.company.is_archived
      } else if (value == "archivé") {
        return company.table.company.is_archived
      } else {
        return true
      }
    })
    google.maps.event.addDomListener(window, "load", initialize_map(filtred_companies));
  });
}

function initialize_map(data) {
  var map = new google.maps.Map(document.getElementById("map"), {
    zoom: 3,
    center: {
      lat: 46.227638,
      lng: 2.213749,
    },
  });
  var infowindow = new google.maps.InfoWindow();
  var markers = data.map(function (location) {
    if (location.table.company.is_archived) {
      var marker;
      var greyMarker = {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        fillColor: "	#C0C0C0",
        fillOpacity: 1,
        strokeColor: "#000000	",
        strokeWeight: 1.8,
        labelOrigin: { x: 0, y: -30 }
      }
      if (location.table.address.latitude && location.table.address.longitude) {
        marker = setMarker(
          location.table.address.latitude,
          location.table.address.longitude,
          greyMarker
        );
        showInfoWindow(infowindow, marker, location);
      } else {
        marker = setMarker(0, 0, greyMarker);
        showInfoWindow(infowindow, marker, location);
      }
      marker.setTitle(location.table.company.name)
      return marker;
    } else {
      var marker;
      if (location.table.address.latitude && location.table.address.longitude) {
        marker = setMarker(
          location.table.address.latitude,
          location.table.address.longitude,
          null
        );
        showInfoWindow(infowindow, marker, location);
      } else {
        marker = setMarker(0, 0, null);
        showInfoWindow(infowindow, marker, location);
      }
      marker.setTitle(location.table.company.name)
      return marker;
    }
  });
  markerclusterer(
    map,
    markers.filter(function (el) {
      return el !== undefined;
    })
  );
}

function showInfoWindow(infowindow, marker, location) {
  google.maps.event.addListener(
    marker,
    "click",
    (function (marker) {
      return function () {
        infowindow.setContent(
          "<b>" +
          location.table.company.name +
          "</b><br><p>" +
          location.table.address.entered_by_user +
          "</p>"
        );
        infowindow.open(map, marker);
      };
    })(marker)
  );
}

function setMarker(lat, lng, icon) {
  if (icon) {
    return new google.maps.Marker({
      position: {
        lat: lat,
        lng: lng,
      },
      icon: icon,
    });
  } else {
    return new google.maps.Marker({
      position: {
        lat: lat,
        lng: lng,
      }
    });
  }
}
// Add a marker clusterer to manage the markers.
function markerclusterer(map, markers) {
  var markersCluster = new MarkerClusterer(map, markers, {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    description: " tracks: click to show",
  });
  var infowindow = new google.maps.InfoWindow();
  google.maps.event.addListener(markersCluster, "mouseover", function (cluster) {
    if (map.getZoom() < map.maxZoom) {
      map.setCenter(cluster.center_);

      map.setZoom(map.getZoom() + 2);
    } else {
      var content = "";
      // Convert the coordinates to an MVCObject
      var info = new google.maps.MVCObject();
      info.set("position", cluster.center_);
      //Get markers
      var marks_in_cluster = cluster.getMarkers();

      for (var z = 0; z < marks_in_cluster.length; z++) {
        content += "<b>" + marks_in_cluster[z].getTitle() + "</b><br>"
      }
      infowindow.close(); // closes previous open ifowindows
      infowindow.setContent(content);
      infowindow.open(map, info);
      google.maps.event.addListener(map, "zoom_changed", function () {
        infowindow.close();
      });
    }
  });
}

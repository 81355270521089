export default function () {
  var c_name = 'angular_route';

  var c_value = window.location.hash;

  var d = new Date();

  d.setTime(d.getTime() + (3 * 60 * 1000));

  var expires = "expires=" + d.toGMTString();

  document.cookie = c_name + "=" + c_value + "; " + expires;
}
